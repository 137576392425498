c
<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>

    <v-data-table
      :headers="headers"
      :items="assets"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="uid"
      :show-expand="expandflag"
      class="elevation-1"
      :items-per-page="roles.itemsPerPage"
      :loading="notloaded"
      loading-text="Loading..."
      :search="search"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Asset List</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">ADD ASSET</v-btn>
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.id"
                          label="Asset ID"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-select
                          v-model="editedItem.type"
                          :items="assetType"
                          :rules="inputrequired"
                          label="Asset Type"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-text-field
                          v-model="editedItem.make"
                          label="Make"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-text-field
                          v-model="editedItem.serialNumber"
                          label="Serial Number"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-text-field v-model="editedItem.assignTo" label="Assigned To" required></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <DatePicker
                          :menu="menupd"
                          :datevalue="editedItem.purchaseDate"
                          :datelabeltext="'Purchase Date'"
                          @dateassinged="editedItem.purchaseDate = $event"
                          :rules="inputrequired"
                        />
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <DatePicker
                          :menu="menuied"
                          :datevalue="editedItem.insuranceExpiryDate"
                          :datelabeltext="'Insurance Expiry'"
                          @dateassinged="editedItem.insuranceExpiryDate = $event"
                        />
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-select
                          v-model="editedItem.theftInsrances"
                          :items="Insurances"
                          label="Insurance"
                          :rules="inputrequired"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.comments"
                          label="comments"
                          required
                          :rules="inputrequired"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text v-if="!spinner" :disabled="!valid" @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />

                  <!--<div class="text-center" v-if="spinner">
               <v-progress-circular :rotate="90" :size="70" :width="7" color="purple"></v-progress-circular>
             </div>  -->
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <!--<template v-slot:[`item.data-table-expand`]="{ slot,expand}">
        <v-icon @click="expand( slot.expand(!slot.isExpanded))">mdi-arrow-down</v-icon>
      </template>-->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <h4>Purchase Date</h4>
              {{ convertDate(item.purchaseDate) }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <h4>Insurance Exp-Date</h4>
              {{ convertDate(item.insuranceExpiryDate) }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="1" lg="1">
              <h4>Insurance</h4>
              {{ item.theftInsrances }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="5" lg="5">
              <h4>Comments</h4>
              {{ item.comments }}
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Asset</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { assetRef } from "../fb"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import { convertAll } from "@/mixins/convertall.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import DatePicker from "@/components/common/Datepicker.vue"
import Spinner from "@/components/common/spinner.vue"
import {mapState} from "vuex"

export default {
  name: "Asset",
  // props:['roles'],
  mixins: [convertAll, collectionsOperation],
  components: { Snackbar, Alertmsg, DatePicker, Spinner },
  data: () => ({
    dialog: false,
    menupd: false,
    menuied: false,
    search: "",
    domain: "",
    lazy: false,
    valid: true,
    notloaded: true,
    singleExpand: false,
    expanded: [],
    expandflag: true,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "Type", value: "type" },
      { text: "Make", value: "make" },
      { text: "SerialNumber", value: "serialNumber" },
      { text: "AssignTo", value: "assignTo" },
      { text: "Purchase Date", value: "purchaseDate", align: " d-none" },
      { text: "InsuranceExpiry", value: "insuranceExpiryDate", align: " d-none" },
      { text: "Insurance", value: "theftInsrances", align: " d-none" },
      { text: "Comments", value: "comments", align: " d-none" },
      { text: "Actions", value: "actions", sortable: false },
    ], //<!--purchaseDate,insuranceExpiryDate,theftInsrances,comments,
    //Purchase Date,Insurance Expiry Date,Theft Insurance,Comments-->
    inputrequired: [(v) => !!v || "Input is required"],
    assets: [],
    editedIndex: -1,

    editedItem: {
      type: null,
      id: null,
      make: null,
      serialNumber: null,
      assignTo: null,
      purchaseDate: null,
      insuranceExpiryDate: null,
      theftInsrances: null,
      comments: null,
      domain: null,
    }, // end of editedItem

    defaultItem: {
      type: null,
      id: null,
      make: null,
      serialNumber: null,
      assignTo: null,
      purchaseDate: null,
      insuranceExpiryDate: null,
      theftInsrances: null,
      comments: null,
      domain: null,
    }, // end of defaultItem

    assetType: ["Hardware", "Software", "Other"],
    Insurances: ["Yes", "No"],
    editflag: false,
    backmenu: "sysadmin",
    snackbar: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    roles: "",
    spinner: false,
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Asset" : "Edit Asset"
    },
    ...mapState({
      currentDomain: state=> state.userProfile.domain
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile

    console.log("Asset crated", this.roles)
    if (this.roles) {
      if (this.roles.role.sysadmin || this.roles.role.readuser) {
        if (this.roles.role.sysadmin) {
          this.editflag = true
        }
        this.domain = this.roles.domain
        this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        this.$router.replace({ name: this.roles.menufrom })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },
  mounted() {
    assetRef.where("domain", "==", this.currentDomain).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
     
          if(!this.notloaded)
          this.updaterealtimedata(this.assets, change)
      
      }) // end of for each
    }) // end of snapshot
  }, // end of mounted
  methods: {
    dateassinged(option, value) {
      if (option == "Insurance Expiry") this.editedItem.insuranceExpiryDate = value
      else if (option == "Purchase Date") this.editedItem.purchaseDate = value
    },

    async initialize() {
      ;(this.assets = []),
        await assetRef
          .where("domain", "==", this.domain)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              var objIndex = this.assets.findIndex((obj) => obj.uid == doc.id)
              if (objIndex < 0)
                this.assets.push({
                  ...doc.data(),
                  uid: doc.id,
                })
            })
            this.notloaded = false
          })
    },

    editItem(item) {
      this.editedIndex = this.assets.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    async deleteItem(item) {
      const deletemsg = "Are you sure you want to delete this asset?" + item.serialNumber
      var Ret = await this.commonDeleteItem(item, this.assets, assetRef, deletemsg)
      console.log("Return from delete Item", Ret)
      if (Ret == true) {
        this.snackbar = true
        this.snackbartext = "The asset has been deleted successfully"
      } else {
        this.alertflag = true
        this.alertmsg = "Error while delete" + Ret
      }
    },

    close() {
      this.dialog = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.editedIndex > -1) {
        Object.assign(this.assets[this.editedIndex], this.editedItem)
        console.log("inside save item ", this.editedItem)
        try {
          await assetRef.doc(this.editedItem.uid).set({
            ...this.editedItem,
          })
          // alert("updated successfully");
          this.snackbar = true
          this.snackbartext = "The asset has been updated successfully"
          this.close()
        } catch (error) {
          console.log("Error while update :", error)
          this.alertflag = true
          this.alertmsg = "Error while update" + error
          this.spinner = false
        }
      } // end of if
      else {
        this.editedItem.domain = this.domain // add this as domain name from login page.
        console.log("inside new item add", this.editedItem)
        /* var queryresult1= await assetRef.where("domain","==",this.domain).where("id","==",this.editedItem.id).get();
          var queryresult2= await assetRef.where("domain","==",this.domain).where("serialNumber","==",this.editedItem.serialNumber).get();*/
        var tmprecord = this.assets.filter(
          (re) => re.id == this.editedItem.id || re.serialNumber == this.editedItem.serialNumber
        )
        if (tmprecord.length == 0) {
          try {
            await assetRef
              .add(this.editedItem)
              .then((docRef) => {
                this.editedItem.uid = docRef.id
              })
              .catch((error) => console.error("Error adding Asset: ", error))
            var objIndex = this.assets.findIndex((obj) => obj.uid == this.editedItem.uid)
            if (objIndex < 0) this.assets.push(this.editedItem)
            this.snackbar = true
            this.snackbartext = "New Asset added successfully"
            this.close()
          } catch (error) {
            this.alertflag = true
            this.alertmsg = "Error while add" + error
            this.spinner = false
          }
        } else {
          this.alertflag = true
          this.alertmsg = "Id / Serialnumber already exists"
          this.spinner = false
        }
      }
    },
  },
}
</script>
